// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

body {
    background: url("/img/bg.jpg") center center / cover no-repeat;
}

b {
    font-weight: 700;
}

a {
    text-decoration: none;
    text-transform: uppercase;
    font-family: $font-family-sans-serif;
    letter-spacing: 4px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-family-sans-serif;
    letter-spacing: 0.5rem;
    text-transform: uppercase;
}

h1 {
    @include media-breakpoint-down(sm) {
        font-size: 1.5625rem;
    }
}

.lead {
    @include media-breakpoint-down(sm) {
        font-size: 1rem !important;
    }
}

.font-family-heading {
    font-family: $font-family-sans-serif;
    letter-spacing: 0.5rem;
    text-transform: uppercase;
}

.location-link {
    svg {
        transition: all 0.3s ease-in-out;
    }

    &:hover {
        svg {
            color: $primary !important;
        }
    }
}

.line {
    position: absolute;
    z-index: 0;
}

.border-r-secondary {
    border-right: 1px solid secondary;

    @include media-breakpoint-down(md) {
        border-right: none;
    }
}

.submit-input {
    text-align: left;

    label {
        display: block;
        text-align: left;
        margin-bottom: 0.125rem;
        font-family: $font-family-sans-serif;
        text-transform: uppercase;
        letter-spacing: 1.6px;
    }

    input {
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid $body-color;
        padding-left: 0;
        padding-top: 0.5rem;
        padding-bottom: 0.75rem;
        width: 100%;
        font-family: $font-family-serif;
        color: $primary;
        font-size: 1.25rem;

        @include media-breakpoint-down(sm) {
            font-size: 1rem;
        }

        &::placeholder {
            color: $secondary;
        }

        &:focus {
            box-shadow: none;
            outline: none;
            border-bottom: 1px solid $primary;
        }
    }

    select {
        background-color: transparent;
        width: 100%;
        border: 0;
        border-bottom: 1px solid $body-color;
        padding-top: 0.5rem;
        padding-bottom: 0.75rem;
        font-family: $font-family-serif;
        color: $primary;
        font-size: 1.25rem;

        @include media-breakpoint-down(sm) {
            font-size: 1rem;
        }

        &:focus {
            box-shadow: none;
            outline: none;
            border-bottom: 1px solid $primary;
        }
    }
}

#authentication {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;

    .auth-form {
        position: relative;
        z-index: 3;

        .auth-input {
            display: flex;
            flex-direction: column;

            &:first-child {
                border-right: 1px solid $secondary;
                margin-right: 1.5rem;
                padding-right: 1.5rem;
            }

            &:last-child {
                border-left: 1px solid $secondary;
                margin-left: 1.5rem;
                padding-left: 1.5rem;
            }

            input {
                background-color: transparent;
                border: none;
                padding: 1rem;
                width: 110px;
                height: 110px;
                text-align: center;
                font-family: $font-family-serif;
                font-size: 4rem;
                color: $primary;

                @include media-breakpoint-down(sm) {
                    width: 75px;
                    height: 60px;
                    font-size: 1.75rem;
                }

                &::placeholder {
                    color: $secondary;
                }

                &:focus {
                    border: none;
                    outline: none;
                    box-shadow: none;
                }
            }

            label {
                text-align: center;
                color: $primary;
                font-family: $font-family-sans-serif;
                letter-spacing: 0.2rem;
                text-transform: uppercase;
                display: inline-block;
                margin-top: 0.5rem;
            }

            input:focus + label {
                color: $primary;
            }

            input:placeholder-shown + label {
                color: $secondary;
            }
        }
    }

    .auth-response {
        opacity: 0;
        color: rgb(236, 81, 81);

        &.shown {
            opacity: 1;
        }
    }

    .line {
        opacity: 0;
        transform: translateY(12rem) scale(0.4);
        transition: all 0.25s ease-in-out !important;
    }

    &.init {
        .line {
            opacity: 1;
            transform: translateY(0) scale(1);
            transition: all 0.6s ease-in-out !important;

            @include media-breakpoint-down(xl) {
                opacity: 0.3;
            }

            @include media-breakpoint-down(lg) {
                opacity: 0.15;
            }
        }
    }

    .line-left-1 {
        left: -150px;
        top: 0;
    }

    .line-left-2 {
        left: -150px;
        top: 0;
        transition-delay: 0.25s;
    }

    .line-right-1 {
        right: 0;
        top: 0;
        transition-delay: 0s;
    }

    .line-right-2 {
        right: 50px;
        top: 0;
        transition-delay: 0.25s;
    }
}

.content-slide > .container {
    opacity: 0;
    margin-top: 0;
    transition: all 0.6s ease-in-out 0.2s;
}

.content-slide.entring-up > .container {
    margin-top: 4rem;
}

.content-slide.entring-down > .container {
    margin-top: 4rem;
}

.content-slide.active > .container {
    opacity: 1;
    margin-top: 0;
}

.content-slide {
    .container {
        position: relative;
        z-index: 2;

        @media (max-height: 855px) {
            transform: scale(0.8);
        }

        @media (max-height: 620px) {
            transform: scale(0.7);
        }

        @media (max-height: 450px) {
            transform: scale(0.6);
        }
    }

    .line {
        opacity: 0;
        transform: translateY(12rem) scale(0.4);
        transition: all 0.25s ease-in-out !important;
        // transition-delay: 0s !important;
    }

    &.active {
        .line {
            opacity: 1;
            transform: translateY(0) scale(1);
            transition: all 0.6s ease-in-out !important;

            @include media-breakpoint-down(xl) {
                opacity: 0.3;
            }

            @include media-breakpoint-down(lg) {
                opacity: 0.15 !important;
            }
        }
    }
}

.content-slide.leaving-up > .line {
    opacity: 0 !important;
    transition: all 0.25s ease-in-out !important;
    transition-delay: 0s !important;
}

.content-slide.leaving-down > .line {
    opacity: 0 !important;
    transition: all 0.25s ease-in-out !important;
    transition-delay: 0s !important;
}

#first-slide {
    .container {
        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: url("/img/castle.jpg") center center / contain no-repeat;
            mix-blend-mode: multiply;
            opacity: 0;
            transform: scale(1);
            transition: all 1s ease-in-out;
            z-index: 1;

            @include media-breakpoint-down(md) {
                background-image: url("/img/castle.jpg");
                background-size: 150%;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }

    .row.my-auto {
        position: relative;
        z-index: 2;
    }

    #regina_font,
    #seventy_font {
        transition: all 0.5s ease-in-out;
        opacity: 0;
    }

    #regina_font {
        transform: translate(-622.441px, 565.01px);
    }

    #seventy_font {
        transform: translate(-289.411px, 791.596px);
        transition-delay: 0.25s;
    }

    .line {
        opacity: 0;
        transform: translateY(12rem) scale(0.4);
        transition: all 0.6s ease-in-out;
    }

    &.init {
        .line {
            opacity: 1;
            transform: translateY(0) scale(1);

            @include media-breakpoint-down(xl) {
                opacity: 0.3;
            }

            @include media-breakpoint-down(lg) {
                opacity: 0.15;
            }
        }
    }

    .line-left-1 {
        left: -150px;
        top: 0;
    }

    .line-left-2 {
        left: -150px;
        top: 0;
        transition-delay: 0.25s;
    }

    .line-right-1 {
        right: 0;
        top: 0;
        transition-delay: 0s;
    }

    .line-right-2 {
        right: 50px;
        top: 0;
        transition-delay: 0.25s;
    }

    &.init {
        .container {
            &::before {
                transform: scale(1.2);
                opacity: 0.5;
            }
        }

        #regina_font,
        #seventy_font {
            opacity: 1;
        }

        #regina_font {
            transform: translate(-622.441px, 535.01px);
        }

        #seventy_font {
            transform: translate(-289.411px, 751.596px);
        }
    }
}

#second-slide {
    .line-left-1 {
        left: -150px;
        top: 0;
        transform: translateY(12rem) scale(0.4) rotate(35deg);
    }

    .line-left-2 {
        left: -100px;
        top: 0;
        transition-delay: 0.25s;
        transform: translateY(12rem) scale(0.4) rotate(35deg);
    }

    .line-right-1 {
        right: -50px;
        top: 0;
        transform: translateY(12rem) scale(0.4) rotate(-15deg);
    }

    .line-right-2 {
        right: 0;
        top: 0;
        transition-delay: 0.25s;
        transform: translateY(12rem) scale(0.4) rotate(-15deg);
    }

    &.active {
        .line-left-1 {
            transform: translateY(0) scale(1) rotate(35deg);
        }
        .line-left-2 {
            transform: translateY(0) scale(1) rotate(35deg);
        }
        .line-right-1 {
            transform: translateY(0) scale(1) rotate(-15deg);
        }
        .line-right-2 {
            transform: translateY(0) scale(1) rotate(-15deg);
        }
    }

    .location-heading {
        @include media-breakpoint-up(md) {
            margin-top: -14px !important;
        }
    }
}

#third-slide {
    .line-left-1 {
        left: -150px;
        top: 0;
        transform: translateY(12rem) scale(0.4) rotate(15deg);
    }

    .line-left-2 {
        left: -250px;
        top: 0;
        transition-delay: 0.25s;
        transform: translateY(12rem) scale(0.4) rotate(15deg);
    }

    .line-right-1 {
        right: -50px;
        top: 0;
        transform: translateY(12rem) scale(0.4) rotate(180deg);
    }

    .line-right-2 {
        right: 0;
        top: 0;
        transition-delay: 0.25s;
        transform: translateY(12rem) scale(0.4) rotate(180deg);
    }

    &.active {
        .line-left-1 {
            transform: translateY(0) scale(1) rotate(15deg);
        }
        .line-left-2 {
            transform: translateY(0) scale(1) rotate(15deg);
        }
        .line-right-1 {
            transform: translateY(0) scale(1) rotate(180deg);
        }
        .line-right-2 {
            transform: translateY(0) scale(1) rotate(180deg);
        }
    }
}

#second-slide,
#third-slide {
    .time-heading {
        @include media-breakpoint-up(md) {
            font-size: 4rem;
        }
    }

    .location-heading {
        @include media-breakpoint-down(md) {
            font-size: 1rem;
        }
    }

    .lead {
        @include media-breakpoint-down(md) {
            font-size: 1.125rem;
        }
    }
}

#fourth-slide {
    .line-left-1 {
        left: -150px;
        top: 0;
        transform: translateY(12rem) scale(0.4) rotate(35deg);
    }

    .line-left-2 {
        left: -100px;
        top: 0;
        transition-delay: 0.25s;
        transform: translateY(12rem) scale(0.4) rotate(35deg);
    }

    .line-right-1 {
        right: -50px;
        top: 0;
        transform: translateY(12rem) scale(0.4) rotate(-15deg);
    }

    .line-right-2 {
        right: 0;
        top: 0;
        transition-delay: 0.25s;
        transform: translateY(12rem) scale(0.4) rotate(-15deg);
    }

    &.active {
        .line-left-1 {
            transform: translateY(0) scale(1) rotate(35deg);
        }
        .line-left-2 {
            transform: translateY(0) scale(1) rotate(35deg);
        }
        .line-right-1 {
            transform: translateY(0) scale(1) rotate(-15deg);
        }
        .line-right-2 {
            transform: translateY(0) scale(1) rotate(-15deg);
        }
    }
}

#fifth-slide {
    .line-left-1 {
        left: -150px;
        top: 0;
        transform: translateY(12rem) scale(0.4) rotate(-5deg);
    }

    .line-left-2 {
        left: -150px;
        top: 50px;
        transition-delay: 0.25s;
        transform: translateY(12rem) scale(0.4) rotate(-5deg);
    }

    .line-right-1 {
        right: -50px;
        top: 0;
        transform: translateY(12rem) scale(0.4) rotate(180deg);
    }

    .line-right-2 {
        right: 0;
        top: 0;
        transition-delay: 0.25s;
        transform: translateY(12rem) scale(0.4) rotate(10deg);
    }

    &.active {
        .line-left-1 {
            transform: translateY(0) scale(1) rotate(-5deg);
        }
        .line-left-2 {
            transform: translateY(0) scale(1) rotate(-5deg);
        }
        .line-right-1 {
            transform: translateY(0) scale(1) rotate(180deg);
        }
        .line-right-2 {
            transform: translateY(0) scale(1) rotate(10deg);
        }
    }
}

.submit-button {
    background-color: $primary;
    color: #ffffff;
    border: 0;
    outline: none;
    padding: 1rem 1rem;
    width: 100%;
    font-family: $font-family-sans-serif;
    letter-spacing: 5px;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
}

#six-slide {
    .container {
        transform: none !important;
    }

    #accept-form-description {
        @include media-breakpoint-down(sm) {
            font-size: 0.825rem !important;
        }
    }

    .icon-text {
        .text-start {
            margin-left: 0.75rem;
        }

        @include media-breakpoint-up(sm) {
            .text-start {
                margin-left: -0.5rem;
            }
        }
    }

    .line-left-1 {
        left: -150px;
        top: 0;
        transform: translateY(12rem) scale(0.4) rotate(15deg);
    }

    .line-left-2 {
        left: -250px;
        top: 0;
        transition-delay: 0.25s;
        transform: translateY(12rem) scale(0.4) rotate(15deg);
    }

    .line-right-1 {
        right: -50px;
        top: 0;
        transform: translateY(12rem) scale(0.4) rotate(180deg);
    }

    .line-right-2 {
        right: 0;
        top: 0;
        transition-delay: 0.25s;
        transform: translateY(12rem) scale(0.4) rotate(180deg);
    }

    &.active {
        .line-left-1 {
            transform: translateY(0) scale(1) rotate(15deg);
        }
        .line-left-2 {
            transform: translateY(0) scale(1) rotate(15deg);
        }
        .line-right-1 {
            transform: translateY(0) scale(1) rotate(180deg);
        }
        .line-right-2 {
            transform: translateY(0) scale(1) rotate(180deg);
        }
    }
}

@import "~fullview/dist/fullview.css";

#fv-dots {
    z-index: 999;
    @include media-breakpoint-down(sm) {
        left: 92%;
    }
}

#fv-dots ul li {
    padding: 8px;
}

#fv-dots ul li a {
    width: 15px;
    height: 15px;
}

#fv-dots ul li a span {
    width: 8px;
    height: 8px;
    background-color: $secondary;
}

#fv-dots ul li a.active span {
    width: 15px;
    height: 15px;
    background-color: $primary;
}

#fv-dots ul li:nth-child(5) a.active span {
    background-color: #ffffff;
}

// Mouse Scroller

.scroll-downs {
    margin: auto;
    width: 34px;
    height: 55px;
}
.mousey {
    width: 3px;
    padding: 10px 15px;
    height: 35px;
    border: 2px solid $secondary;
    border-radius: 25px;
    opacity: 0.75;
    box-sizing: content-box;
}
.scroller {
    width: 2px;
    height: 10px;
    border-radius: 25%;
    background-color: $secondary;
    animation-name: scroll;
    animation-duration: 2.2s;
    animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
    animation-iteration-count: infinite;
}
@keyframes scroll {
    0% {
        opacity: 0;
    }
    40% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(15px);
        opacity: 0;
    }
}

.fv-tooltip .fv-tooltiptext {
    width: 200px;
    font-size: 14px;
    background: $primary;
    color: #ffffff;
}
