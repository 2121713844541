// Body
$body-bg: #f8fafc;
$body-color: #c29c96;

// Colors
$primary: #202f55;
$secondary: #E6C4BF;
$headings-color: $primary;

// Typography
$font-family-sans-serif: "Mukta", sans-serif;
$font-family-serif: "PT Serif", serif;
$font-family-base: $font-family-serif;
$font-size-base: 1rem;

$h1-font-size: $font-size-base * 2.8125;
